.App {
  max-width: 1980px;
  margin: 0 auto;
  padding: 0 ;
  text-align: center;
  background-color: #030f33;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Abel', sans-serif;
}

.App-logo {
  width: 50vw;
  transform: rotate(-5deg);
}

.App-plaats {
  width: 150px;
  transform: translate(-200%, 0);
}

.App-text {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  font-size: 19px;
}

.section {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px;
  background-color: #ecf0f1;
  color: #2c3e50;
}

.page {
  width: 100vw;
  margin: 0;
  padding: 0;
}

.activiteitpagina {
  background-color: #ecf0f1;
  color: #2c3e50;
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 65vh 1fr;
  text-align: left;
}

.activiteitpagina-text {
  height: auto;
  min-height: 50vh;
  width: 100vw;
  z-index: 1;
  padding: 60px 10vw 60px;
}

.activiteitpagina-text h1 {
  width: 40vw;
}

.activiteitpagina-text h2 {
  width: 40vw;
}

.activiteitpagina-text h3 {
  width: 40vw;
  font-style: italic;
}

#standard-required{
  color: white;
}

@media only screen and (max-width: 850px) {
  .activiteitpagina-text {
    height: auto;
    min-height: 50vh;
    z-index: 1;
    padding: 50px 30px 50px;
  }

  .activiteitpagina-text h1 {
    width: 100%;
  }

  .activiteitpagina-text h2 {
    width: 100%;
  }

  .activiteitpagina-text h3 {
    width: 100%;
  }

  .App-plaats {
    transform: translate(0,0);
    margin-top: 5vh;
    margin-bottom: 3vh;
    width: 100px;
  }

  #intro-text {
    margin-top: 5vh;
    margin-bottom: 2vh;
  }

  .App-text {
    width: 90vw;
    font-size: 17px;
    margin-bottom: 5vh;
  }

  .App-logo {
    width: 90vw;
    height: auto;
  }
  .list-dates {
    width: 90vw;
    margin: 0 auto;
    height: 100px;
    margin-bottom: 2vh;
  }

  .App-bier {
    transform: translate(0,0);
  }

}
