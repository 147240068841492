#orderDisplay {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
}

#orderDisplay-block {
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-template-rows: 1fr 50px;
  grid-row-gap: 30px;
  width: 60vw;
  max-width: 1000px;
  margin: auto;
}

#orderDisplay-block .btn{
  grid-column-start: 1;
  grid-column-end: 3;
  margin: auto;
  width: auto;
}

.orderDisplay-left {
  background-color: white;
}

.orderDisplay-right {
  background-color: #2c3e50;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 15px;
}

.orderDisplay-right h2 {
  margin-top: 15px;
  margin-bottom: 20px;
}

.orderDisplay-right p {
  margin: 0px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1000px) {
  #orderDisplay-block {
    display: block;
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .orderDisplay-left {
    height: 30vh;
  }

  #orderDisplay-block .btn{
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 400px) {


  .orderDisplay-left {
    height: 20vh;
  }

  .orderDisplay-right {
    width: 90vw;
  }
}
