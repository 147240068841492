body {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.Countdown{
  position: absolute;
  top: 88vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  margin: 10px auto;
  padding-bottom: 20px;
  width: 100%;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 50px;
}

@media only screen and (max-width: 800px) {
  .Countdown-col-element{
    margin: 0 10px;
  }

  .Countdown-col-element strong{
    font-size: 30px;
  }
}
